import React from "react";
import Header from "../../components/header/header";
import MailIcon from "../../img/mail-icon-green.svg";
import styles from "../../styles/pages/auth.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../../img/logo_full.svg";

export default function CheckMailbox() {
  const { t } = useTranslation("auth");

  return (
    <div className={`${styles.auth} ${generalStyles.alignTextCenter}`}>
      <Header fullWidth={true} />
      <div className={styles.middle_wrapper}>
        <div className={styles.box}>
          <div className={styles.image}>
            <img src={Logo} alt="Logo" width={170} height={40}></img>
          </div>

          <div className={styles.mailboxImage}>
            <img
              src={MailIcon}
              alt={t("email-imgage-alt")}
              width={120}
              height={120}
            />
          </div>

          <div className={`${generalStyles.authTitle} ${generalStyles.small}`}>
            {t("check-mailbox")}
          </div>
          <div
            className={`${generalStyles.authText} ${styles.textMargin} ${styles.textMailbox}`}
          >
            {t("email-sent")}
          </div>

          <div className={styles.not_activated}>
            {t("not-activated")}
            <br></br>
            {t("not-activated_one")}
            <Link to="/auth/activate-account"> {t("here")} </Link>
            {t("not-activated_two")}
          </div>
        </div>
      </div>
    </div>
  );
}
