import React from "react";
import { NewsItem } from "../services/news.service";
import styles from "../styles/pages/news.module.scss";
import generalStyles from "../styles/generals.module.scss";
import Story from "../components/story/story";
import { useTranslation } from "react-i18next";
import Loading from "../components/loading/Loading";

export default function News({
  news,
  isLoading,
}: {
  news: NewsItem[];
  isLoading: boolean;
}) {
  const { t } = useTranslation("news");

  const checkImg = (news_item: NewsItem) => {
    if (news_item.content_type == "news") {
      return process.env.REACT_APP_IMG_URL + "/" + news_item.field_media_image;
    } else if (news_item.content_type == "press_item") {
      return process.env.REACT_APP_IMG_URL + "/" + news_item.field_header_image;
    } else {
      return "/img/defaults/story.png";
    }
  };

  const checkLink = (news_item: NewsItem) => {
    if (news_item.content_type == "news") {
      return news_item.path;
    } else {
      return news_item.field_pi_link;
    }
  };

  return (
    <>
      <div className={`${generalStyles.title}`}>{t("news")}</div>
      {news && news.length > 0 ? (
        <div className={styles.view}>
          {news.map((newsItem, key) => (
            <Story
              key={key}
              title={newsItem.title}
              date={`${newsItem.field_ni_datum}`}
              badge={newsItem.field_type}
              image={checkImg(newsItem)}
              link={checkLink(newsItem)}
            />
          ))}
        </div>
      ) : (
        <h3> {isLoading ? <Loading /> : t("no_news")}</h3>
      )}
    </>
  );
}
