import React, { useEffect, useState } from "react";
import styles from "../../styles/pages/investment.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import ProjectService, {
  InvestmentDetailItem,
} from "../../services/project.service";
import Number from "../../components/number/number";
import BenefitsBlocks from "../../components/benefits/benefits";
import EuroImage from "../../img/euro-blue.svg";
import EarnImage from "../../img/earn-arrow.svg";
import EarnImagePurple from "../../img/earn-arrow-purple.svg";
import PresentImage from "../../img/present-no-box.svg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import UserService from "../../services/user.service";
import PopupUE from "../../components/popup/Popup";
import Moment from "moment";

export default function InvestmentDetail() {
  const { id } = useParams();
  const { t } = useTranslation("investment");
  const [popup, setPopup] = useState<boolean>();
  const [popup_confirm, setPopupConfirm] = useState<boolean>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const [investmentDetails, setInvestmentDetails] = useState<
    InvestmentDetailItem | undefined
  >(undefined);

  const parsedId: string = Array.isArray(id)
    ? id.length > 0
      ? id[0]
      : undefined
    : id;

  useEffect(() => {
    ProjectService.getEntity(parsedId).then((response) => {
      setInvestmentDetails(response.data);
    });
  }, [parsedId]);

  const onClick = (id: number) => {
    setSubmitting(true);
    UserService.ValuationRequest({
      project_id: id,
    });
    return new Promise<void>(() => {
      setTimeout(() => {
        setPopupConfirm(false);
        openPopup();
        setSubmitting(false);
      }, 1500);
    });
  };

  const openConfirmPopup = () => {
    setPopupConfirm(true);
  };
  const closeConfirmPopup = () => {
    setPopupConfirm(false);
  };

  const openPopup = () => {
    setPopup(true);
  };
  const closePopup = () => {
    setPopup(false);
  };

  return (
    <>
      {investmentDetails ? (
        <div className={styles.invest}>
          <div
            className={`${generalStyles.title} ${generalStyles.align_center}`}
          >
            {investmentDetails ? investmentDetails.title : t("loading")}
          </div>
          <div className={styles.investment_day_wrapper}>
            {investmentDetails.investment_date ? (
              <div className={styles.investment_day}>
                {t("investment_date")} {investmentDetails?.investment_date}
              </div>
            ) : (
              ""
            )}

            {investmentDetails.investment_date_expire &&
            new Date(investmentDetails.investment_date_expire) > new Date() ? (
              <div className={styles.investment_day_expire}>
                {t("locked_until")}
                {investmentDetails?.investment_date_expire}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={styles.investment_data_wrapper}>
            <img
              className={styles.image}
              src={`${investmentDetails.image}`}
              alt="project image"
            />
            <div className={styles.numbers}>
              <Number
                singleBlock={investmentDetails.roi === 0}
                icon={EarnImage}
                number={investmentDetails.amount_invested}
                unit={t("invested")}
                bgColor="green"
                prefix="€ "
              />
              {investmentDetails.roi === 0 ? null : (
                <>
                  <Number
                    icon={EuroImage}
                    number={investmentDetails.total_return}
                    unit={t("Distribution (total)")}
                    bgColor="blue"
                    prefix="€ "
                  />
                  <Number
                    icon={EarnImagePurple}
                    number={investmentDetails.roi}
                    unit={t("Distribution (this year)")}
                    bgColor="purple"
                    suffix="%"
                  />
                </>
              )}
            </div>

            {investmentDetails.vme ? (
              <div className={styles.waardering}>
                <div className={`${styles.benefit_block}`}>
                  <img
                    src={EuroImage}
                    alt="project image"
                    width={48}
                    height={48}
                  />
                  <div className={styles.text}>
                    {t("check_up_request_title")}
                    <div>
                      <a
                        className={styles.link}
                        onClick={() => {
                          openConfirmPopup();
                        }}
                      >
                        {t("check_up_request_cta")}
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <PopupUE
                    title={t("check_up_request_confirm_title")}
                    body={
                      <button
                        disabled={isSubmitting}
                        onClick={() => onClick(investmentDetails.id)}
                        className={`${generalStyles.button_modal} ${generalStyles.green}`}
                      >
                        {isSubmitting ? (
                          <span className={generalStyles.spinner}></span>
                        ) : (
                          t("check_up_request_confirm_cta")
                        )}
                      </button>
                    }
                    icon=""
                    opened={popup_confirm}
                    close={closeConfirmPopup}
                  />
                </div>
                <div>
                  <PopupUE
                    title={t("popup_checkup_title")}
                    body={t("popup_checkup_body")}
                    icon=""
                    opened={popup}
                    close={closePopup}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.sell_wrapper}>
                <BenefitsBlocks
                  text={t("sell_body")}
                  linkText={t("sell_cta")}
                  link={`/investments/${parsedId}/sell`}
                  img={PresentImage}
                  position="img_position_right"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
