import React from "react";
import styles from "./header.module.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import NotificationsBell from "../notifications/notificationsBell";
import { NotificationItem } from "../../services/notifications.service";

export default function Header({
  fullWidth,
  onBoardingSetting,
  notifications,
  setNotifications,
  isLoading,
  onboarding,
}: {
  fullWidth: boolean;
  onBoardingSetting?: string;
  notifications?: NotificationItem[];
  setNotifications?: (items: NotificationItem[]) => void;
  isLoading?: boolean;
  onboarding?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const locationsWithoutBackButton: Record<string, string[]> = {
    "/": [],
    "/loyalty": [],
    "/investments": [],
    "/benefits": ["/investments"],
    "/news": [],
    "/events": [],
    "/documents": [],
    "/profile": ["/"],
    "/profile/helpcenter": ["/profile"],
  };

  function backButtonAllowed() {
    if (locationsWithoutBackButton[location.pathname] === undefined) {
      return true;
    }

    if (
      locationsWithoutBackButton[location.pathname] !== undefined &&
      prevLocation &&
      locationsWithoutBackButton[location.pathname].includes(
        prevLocation.pathname
      )
    ) {
      return true;
    }

    return false;
  }

  return (
    <header
      id={`${styles.header}`}
      className={`${fullWidth ? styles.fullwidth : ""} ${
        onboarding ? styles.header_onboarding : ""
      } `}
    >
      {!location.pathname.startsWith("/auth/") &&
      onBoardingSetting === "done" &&
      backButtonAllowed() ? (
        <button className={`${styles.return}`} onClick={() => navigate(-1)}>
          Return
        </button>
      ) : (
        ""
      )}

      <Link to="/">
        <h1 className={styles.h1}>My Upgrade</h1>
      </Link>
      {location.pathname !== "/notifications" &&
      !location.pathname.startsWith("/auth/") &&
      onBoardingSetting === "done" &&
      notifications &&
      setNotifications &&
      isLoading !== undefined ? (
        <div className={styles.notifications}>
          <NotificationsBell
            setNotifications={setNotifications}
            notifications={notifications}
            isLoading={isLoading}
          />
        </div>
      ) : null}
    </header>
  );
}
