import React, { useState } from "react";
import Header from "../../components/header/header";
import SetupImage from "../../img/setup.svg";
import styles from "../../styles/pages/auth.module.scss";
import formStyles from "../../styles/forms/forms.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import AuthService from "../../services/auth.service";
import Logo from "../../img/logo_full.svg";

export default function AskForAccount() {
  const { t } = useTranslation("auth");
  const [email, setEmail] = useState("");
  const navigation = useNavigate();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return new Promise<void>(() => {
      setTimeout(() => {
        AuthService.activateAccount({
          email: data.email,
        }).then((response) => {
          if (response) {
            reset();
            setSubmitting(false);
            navigation("/auth/check-account");
          } else {
            reset();
            setSubmitting(false);
            setError(true);
          }
        });
      }, 2000);
    });
  };

  return (
    <div className={`${styles.auth} ${styles.account}`}>
      <Header fullWidth={true} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.box}>
          <div className={styles.image}>
            <img src={Logo} alt="Logo" width={170} height={40}></img>
          </div>
          <div className={generalStyles.authTitle}>
            {t("ask_account_title")}
          </div>
          <div className={`${generalStyles.authText} ${styles.textMargin}`}>
            {t("ask_account_subtitle")}
          </div>

          <input
            className={`${generalStyles.input} ${
              errors.email ? formStyles.error : ""
            }`}
            placeholder={t("E-mailadres")}
            value={email}
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            type={"email"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {errors.email && (
            <p className={formStyles.error_message}>Email field is required.</p>
          )}
          <div className={styles.button_wrapper}>
            <button
              disabled={isSubmitting}
              type="submit"
              className={generalStyles.button}
            >
              {isSubmitting ? (
                <span className={generalStyles.spinner}></span>
              ) : (
                t("ask_account_button")
              )}
            </button>
            <div className={`${styles.accountText} ${styles.margin}`}>
              {t("login-text-1")} <Link to="/auth/login">{t("here")}</Link>{" "}
              {t("login-text-2")}
            </div>
          </div>

          {error ? (
            <div className={styles.not_exists}>
              {t("not_exists")}
              <a href={`mailto:${process.env.REACT_APP_HELP_EMAIL}`}>
                {t("not_exists_1")}
              </a>
              {t("not_exists_2")}
            </div>
          ) : (
            ""
          )}

          <div className={`${styles.accountText} ${styles.margin}`}>
            {t("terms")} <a href={t("privacy_link")}>{t("privacy")}</a>
          </div>
        </div>
      </form>
    </div>
  );
}
