import React, { useEffect, useState } from "react";
import generalStyles from "../../styles/generals.module.scss";
import CardWithIcon from "../../components/card-with-icon/card-with-icon";
import ClockImage from "../../img/clock.svg";
import ManImage from "../../img/man.svg";
import ContractImage from "../../img/contract.svg";
import GiftImage from "../../img/gift.svg";
import SellImage from "../../img/sell.svg";
import SellContractImage from "../../img/sellcontract.svg";
import styles from "../../styles/pages/sell.module.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EuroImage from "../../img/icons/194142/icon-euro.svg";
import UserService from "../../services/user.service";
import PopupUE from "../../components/popup/Popup";
import ProjectService, {
  InvestmentDetailItem,
} from "../../services/project.service";

export default function Sell() {
  const { id } = useParams();
  const { t } = useTranslation("investment");
  const [popup, setPopup] = useState<boolean>();
  const [popup_confirm, setPopupConfirm] = useState<boolean>();
  const [investmentDetails, setInvestmentDetails] = useState<
    InvestmentDetailItem | undefined
  >(undefined);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [lockedInvestment, setLockedInvestment] = useState<boolean>(false);

  const parsedId: string = Array.isArray(id)
    ? id.length > 0
      ? id[0]
      : undefined
    : id;

  // Function to transform the date format
  const transformDate = (date: string) => {
    return date.replace(/\//g, "-"); // Replace '/' with '-'
  };

  useEffect(() => {
    ProjectService.getEntity(parsedId).then((response) => {
      setInvestmentDetails(response.data);
    });
  }, [parsedId]);

  useEffect(() => {
    const check_investment_date = investmentDetails?.investment_date;
    const currentDate = new Date();

    if (check_investment_date !== undefined) {
      const investment_date_transform = transformDate(check_investment_date);
      const locked_date = new Date(investment_date_transform);
      if (locked_date.toDateString() > currentDate.toDateString()) {
        setLockedInvestment(true);
      }
    }
  });

  const onClick = (id: number) => {
    setSubmitting(true);
    UserService.ValuationRequest({
      project_id: id,
    });
    return new Promise<void>(() => {
      setTimeout(() => {
        setPopupConfirm(false);
        openPopup();
        setSubmitting(false);
      }, 1500);
    });
  };

  const openConfirmPopup = () => {
    setPopupConfirm(true);
  };
  const closeConfirmPopup = () => {
    setPopupConfirm(false);
  };

  const openPopup = () => {
    setPopup(true);
  };
  const closePopup = () => {
    setPopup(false);
  };

  return (
    <>
      <div className={styles.sell}>
        <div className={`${generalStyles.title} ${generalStyles.align_center}`}>
          {t("sell_title")} / {t("donate_title")}
        </div>
        <div className={styles.subtitle}>{t("sell_donate_intro_text")}</div>

        {investmentDetails ? (
          <div className={styles.options}>
            <CardWithIcon
              title={t("want_to_donate")}
              icon={GiftImage}
              conditions={[
                {
                  icon: ClockImage,
                  text: t("want_to_donate_usp_1"),
                },
                {
                  icon: ManImage,
                  text: t("want_to_donate_usp_2"),
                },
              ]}
              link={`/investments/${id}/submit-sell?type=donate`}
              link_text={t("option_cta")}
            />
            <CardWithIcon
              title={t("want_to_sell_to_ue")}
              icon={SellImage}
              conditions={[
                {
                  icon: ClockImage,
                  text: t("want_to_sell_to_ue_usp_1"),
                },
                {
                  icon: ContractImage,
                  text: t("want_to_sell_to_ue_usp_2"),
                },
              ]}
              link={`/investments/${id}/submit-sell?type=sell`}
              link_text={t("option_cta")}
              locked={lockedInvestment}
            />
            <CardWithIcon
              title={t("want_to_sell")}
              icon={SellContractImage}
              conditions={[
                {
                  icon: ClockImage,
                  text: t("want_to_sell_usp_1"),
                },
                {
                  icon: ManImage,
                  text: t("want_to_sell_usp_2"),
                },
              ]}
              link={`/investments/${id}/submit-sell?type=sell`}
              link_text={t("option_cta")}
              locked={lockedInvestment}
            />
          </div>
        ) : (
          ""
        )}
        {investmentDetails ? (
          <div className={styles.waardering}>
            <div className={`${styles.benefit_block}`}>
              <img src={EuroImage} alt="project image" width={48} height={48} />
              <div className={styles.text}>
                {t("check_up_request_title")}
                <div>
                  <a
                    className={styles.link}
                    onClick={() => {
                      openConfirmPopup();
                    }}
                  >
                    {t("check_up_request_cta")}
                  </a>
                </div>
                {/* {investmentDetails.roi === 0 ? null : (
                  <div className={styles.movable}>
                    <i>{t("movable_text")}</i>
                  </div>
                )} */}
              </div>
            </div>

            <div>
              <PopupUE
                title={t("check_up_request_confirm_title")}
                body={
                  <button
                    disabled={isSubmitting}
                    onClick={() => onClick(investmentDetails.id)}
                    className={`${generalStyles.button_modal} ${generalStyles.green}`}
                  >
                    {isSubmitting ? (
                      <span className={generalStyles.spinner}></span>
                    ) : (
                      t("check_up_request_confirm_cta")
                    )}
                  </button>
                }
                icon=""
                opened={popup_confirm}
                close={closeConfirmPopup}
              />
            </div>
            <div>
              <PopupUE
                title={t("popup_checkup_title")}
                body={t("popup_checkup_body")}
                icon=""
                opened={popup}
                close={closePopup}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
