import React, { useEffect, useState } from "react";
import Logo from "../../img/logo_full.svg";
import styles from "../../styles/pages/auth.module.scss";
import generalStyles from "../../styles/generals.module.scss";
import PasswordInput from "../../components/password-input/password-input";
import AuthService from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { AvailableResult, NativeBiometric } from "capacitor-native-biometric";
import { Preferences } from "@capacitor/preferences";
import { Dialog } from "@capacitor/dialog";
import { Capacitor } from "@capacitor/core";
import Header from "../../components/header/header";

export default function Login({
  isLoggedIn,
}: {
  isLoggedIn: (bool: boolean) => void;
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigation = useNavigate();
  const { t } = useTranslation("auth");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [biometricAvailable, setBiometricAvailable] = useState<boolean>(false);

  async function biometricLogin(res: AvailableResult) {
    if (!res.isAvailable) {
      setBiometricAvailable(false);
      return;
    }

    const verified = await NativeBiometric.verifyIdentity({
      negativeButtonText: t("negative-button-text"),
      maxAttempts: 3,
    })
      .then(() => true)
      .catch(() => false);

    if (!verified) {
      setBiometricAvailable(false);
      return;
    }

    const credentials = await NativeBiometric.getCredentials({
      server: process.env.REACT_APP_HOSTNAME || "",
    });

    await submitCredentials(credentials.username, credentials.password);
  }

  async function submitCredentials(email: string, password: string) {
    localStorage.clear();
    const result = await AuthService.login(email, password);

    if (!result) {
      setError(true);
      setSubmitting(false);
    } else {
      isLoggedIn(true);

      if (Capacitor.getPlatform() !== "web") {
        Preferences.get({ key: "biometricEnabled" }).then(async (res) => {
          if (!res.value) {
            Dialog.confirm({
              title: t("confirmation-dialog-title"),
              message: t("confirmation-dialog-message"),
              okButtonTitle: t("confirmation-dialog-confirm"),
              cancelButtonTitle: t("confirmation-dialog-cancel"),
            }).then(async (res) => {
              await Preferences.set({
                key: "biometricEnabled",
                value: res.value.toString(),
              });
            });
          }
          await NativeBiometric.deleteCredentials({
            server: process.env.REACT_APP_HOSTNAME || "",
          });
          await NativeBiometric.setCredentials({
            username: email,
            password: password,
            server: process.env.REACT_APP_HOSTNAME || "",
          });
        });
      }

      await navigation("/");
    }
  }

  useEffect(() => {
    Preferences.get({ key: "biometricEnabled" }).then((res) => {
      if (res.value === "true") {
        NativeBiometric.isAvailable().then(async (res) => {
          setBiometricAvailable(true);
          await biometricLogin(res);
        });
      }
    });
  }, []);

  return (
    <div className={styles.auth}>
      <Header fullWidth={true} />

      {biometricAvailable ? (
        <>
          <div>{t("use-biometric-login")}</div>
          <button
            className={`${styles.margin} ${generalStyles.button}`}
            onClick={() => setBiometricAvailable(false)}
          >
            {t("negative-button-text")}
          </button>
        </>
      ) : (
        <form
          onSubmit={async (e) => {
            setSubmitting(true);
            e.preventDefault();
            await submitCredentials(email, password);
          }}
        >
          <div className={styles.box}>
            <div className={styles.image}>
              <img src={Logo} alt="Logo" width={170} height={40}></img>
            </div>
            <div className={generalStyles.authTitle}>{t("login2")}</div>
            <div className={`${generalStyles.authText} ${styles.textMargin}`}>
              {t("warning")}
            </div>
            <input
              className={generalStyles.input}
              type="email"
              placeholder={t("email")}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required={true}
            />
            <PasswordInput
              placeholder={t("password")}
              value={password}
              setValue={setPassword}
              required={true}
            />
            <div className={styles.warningText}>
              <Link to="/auth/password-reset-request">{t("forgot")}</Link>
            </div>
            <div className={styles.button_wrapper}>
              <button
                disabled={isSubmitting}
                type="submit"
                className={`${styles.margin} ${generalStyles.button}`}
              >
                {isSubmitting ? (
                  <span className={generalStyles.spinner}></span>
                ) : (
                  t("login")
                )}
              </button>
            </div>

            {error ? (
              <div className={styles.errorText}>{t("error")}</div>
            ) : null}

            <div className={styles.not_activated}>
              {t("not-activated")}
              <br></br>
              {t("not-activated_one")}
              <Link to="/auth/activate-account"> {t("here")} </Link>
              {t("not-activated_two")}
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
